<script lang="ts">
    import type { StoresKey } from "~/stores";
    import { getStoresContext } from "~/stores";
    import { findNavigationByType } from "~/util/nav";
    import BodyContent from "../BodyContent.svelte"
    import SocialMediaLinks from "./SocialMediaLinks.svelte"
    import PageIntro from "./PageIntro.svelte";
    import InfoLift from "../InfoLift.svelte";

    export let request_store_key: StoresKey;
    export let fragment: Fragment;

    const stores_context = getStoresContext(request_store_key);
    const { nav_menus } = stores_context;
    const { getFragmentConfig, getHtml } = stores_context.fieldUtils();

    let title = '';
    let subtitle = ''; 
    let social: any;
    let flipOrder:any;

    $: {
      title = getHtml("main_title", fragment);
      subtitle = getHtml("intro_text", fragment);
      flipOrder = getFragmentConfig("flipOrder", fragment);
      [social] = findNavigationByType($nav_menus, "Social");
    }

</script>


  <BodyContent>
    <div class="info-lift-wrapper">
      <div class="info-lift-content row">
        <div class="col-12 col-lg-8 col-xl-9 extra-padding text-center">
          <div class="col-lg-10 offset-lg-1">
            <PageIntro
              {title}
              introduction={subtitle}
              flip_order={flipOrder}
            />
            <div class="left-some-wrapper d-none d-lg-block">
              <SocialMediaLinks {social}/>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 p-0">
          <InfoLift {request_store_key}/>
        </div>
      </div>
    </div>
  </BodyContent>

<style lang="scss">
  
  .info-lift-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    color: #4e545b;
  }

  .info-lift-content {
    position: relative;
    padding: 48px 24px;

    @media screen and (min-width: 992px) {
      padding: 80px 80px 0;
    }
  }

  //Copy bootstrap grid styles to match old site

  @media (min-width: 992px) {
    .col-lg-10 {
      width: 83.33333333%;
    }
  }
  .col-lg-10 {
    padding: 0;
  }
</style>
