<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { findNavigationByType } from "~/util/nav";
  import SocialMediaLinks from "./home/SocialMediaLinks.svelte";
  import  Collapse, { EXPAND_TRESHOLD } from "./common/Collapse.svelte";
  import OpeningHours from "./header/OpeningHours.svelte"
  import PillButton from "./PillButton.svelte"

  export let collapsable = true;
  export let show_all_hours = true;
  export let request_store_key: StoresKey;

  const { T, reverse_router, site, nav_menus} = getStoresContext(request_store_key);

  $: t = $T;
  $: [social] = findNavigationByType($nav_menus, "Social");
  $: opening_hours_link = $reverse_router.routePurpose("opening hours");
  $: arriving_link = $reverse_router.routePurpose("custom1");
  $: info_link = $reverse_router.routePurpose("custom1");
  $: check_hours_link = $reverse_router.routePurpose("stores collection");
  $: floormap_link = $reverse_router.routePurpose("floor map");

  let expand_treshold = collapsable ? EXPAND_TRESHOLD : 0;
  $:arriving_text = $site.shoppingCenterAddress?.split(",").join("\n");
  $:info_text = `${$site.contactNumber}\n${$site.contactEmail}`
  
</script>

<Collapse expand_treshold={0}>
  <img slot="icon" class="icon" src="/theme/mylly2023/assets/mylly-clock.svg" alt="" />
  <div slot="title" class="aside-title">
    <span>{t`opening-hours`}</span>
    <a href={opening_hours_link}>{t`opening-hours`}</a>
  </div>
  <div class="aside-description mb-3">
    <OpeningHours {request_store_key}/>
  </div>
</Collapse>

{#if collapsable}<hr />{/if}

<Collapse {expand_treshold}>
  <img slot="icon" class="icon" src="/theme/mylly2023/assets/mylly-map.svg" alt="" />
  <div slot="title" class="aside-title">
    <span>{t`arrival`}</span>
    <a href={arriving_link}>{t`arrival`}</a>
  </div>
  <p class="aside-description pre-content">{@html arriving_text}</p>
</Collapse>

{#if collapsable}<hr />{/if}


<Collapse {expand_treshold}>
  <img slot="icon" class="icon" src="/theme/mylly2023/assets/mylly-info.svg" alt="" />
  <div slot="title" class="aside-title">
    <span>{t`info`}</span>
    <a href={info_link}>{t`info`}</a>
  </div>
  <p class="aside-description pre-content">{@html info_text}</p>
</Collapse>

{#if collapsable}<hr />{/if}

<div class="socialmedia d-block d-lg-none">
  <SocialMediaLinks {social}/>
</div>

{#if show_all_hours}
  <div class="links">
    <PillButton href={check_hours_link}>{t`click-for-all-opening-hours`}</PillButton>
    <PillButton href={floormap_link}>{t`open-map`}</PillButton>
  </div>
{/if}

<!-- </div> -->
<style lang="scss">
  hr {
    border-bottom: 1px solid #4c4c4c;
    @media screen and (min-width: 992px) {
      opacity: 0;
    }
  }

  .socialmedia {
    width: 100%;
    padding: 40px 0;
    margin: 0 auto;
    text-align: center;
  }

  .links :global(.pill-button) {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
  }

  /* .aside { */
  @media screen and (min-width: 992px) {
    :global(.info-lift-content .collabsible) .icon {
      margin-bottom: -16px;
    }

    /* :global(.info-lift-content .collabsible) .description {
      padding: 0;
    } */
  }

  .aside-title {
    position: relative;
    padding: 8px 0;
    color: inherit;
    font-family: var(--font-mono);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.12em;
    text-decoration: none;
    text-transform: uppercase;

    a {
      display: none;
      color: inherit;
    }

    @media screen and (min-width: 992px) {
      text-underline-position: auto;

      span {
        display: none;
      }
      a {
        display: inline;
      }

      :global(footer) &,
      :global(footer) & a {
        text-decoration: none;
      }
    }
  }

  .aside-description {
    padding-left: calc(30px + 16px);
    font-size: 18px;
    line-height: 24px;
    text-align: left;

    &.pre-content {
      white-space: pre;
    }

    :global(a) {
      color: inherit;
    }

    @media screen and (min-width: 992px) {
      padding-left: calc(45px + 16px);
    }
  }
</style>
